import FormFirstAccess from "./components/FormFirstAccess"

const FirstAccess = ({ location }) => {
  return (
    <div className="first-access">
      <FormFirstAccess location={location} />
    </div>
  )
}

export default FirstAccess
