import { navigate } from "gatsby"
import { useState } from "react"
import { useParameters } from "hooks/useParameters"
import services from "services"

const useFormFirstAccess = ({ location }) => {
  const [registered, setRegistered] = useState()

  const {
    token: [token],
    email: [email],
  } = useParameters("token", "email")

  const onSubmit = (data) => {
    services.auth.signup({ token, email, ...data }).then(handleFirstAccess)
  }

  const onConcluded = () => navigate(ROUTES.login.path)

  const handleFirstAccess = ({ error }) => {
    if (!error) setRegistered(true)
  }

  return {
    registered,
    onSubmit,
    onConcluded,
  }
}

export default useFormFirstAccess
