import Form from "components/Forms/Form"
import Input from "components/Forms/Input"
import If from "components/If"
import Button from "components/Button"
import AuthFeedback from "components/Auth/Feedback"

import useFormFirstAccess from "./hooks"
import { values, validation } from "./form"

import "./FormFirstAccess.styl"

const FormFirstAccess = ({ location }) => {
  const { registered, onSubmit, onConcluded } = useFormFirstAccess({
    location,
  })

  return (
    <If
      condition={registered}
      renderElse={
        <div className="form-first-access">
          <h1 className="form-first-access__title logged-out__title">
            Primeiro acesso
          </h1>
          <p
            className="form-first-access__description logged-out__description logged-out__description--orange"
            data-test="welcome"
          >
            Bem-vindo! Para seu primeiro acesso, precisamos que você crie uma
            nova senha.
          </p>
          <p
            className="form-first-access__description logged-out__description"
            data-test="description"
          >
            Sua nova senha deve ter pelo menos 8 caracteres contendo no mínimo 1
            maiúsculo, 1 minúsculo e 1 especial.
          </p>
          <Form
            className="form-first-access__form"
            defaultValues={values}
            onSubmit={onSubmit}
            schema={validation}
          >
            <Input
              className="form-first-access__input"
              name="password"
              type="password"
              placeholder="Nova senha"
              data-test="password"
            />
            <Input
              className="form-first-access__input"
              name="confirm_password"
              type="password"
              placeholder="Confirmar a nova senha"
              data-test="confirm-password"
            />
            {({ state: { isDisabled } }) => (
              <Button
                className="form-first-access__submit"
                type="submit"
                size="big"
                data-test="submit"
                disabled={isDisabled}
              >
                Enviar
              </Button>
            )}
          </Form>
        </div>
      }
      renderIf={
        <AuthFeedback
          icon="sent"
          title="Senha criada"
          description="Sua senha nova foi cadastrada e você já pode usar para acessar o sistema."
          button="concluído"
          data-test="success"
          onClick={onConcluded}
        />
      }
    />
  )
}

export default FormFirstAccess
